import "core-js/modules/es.array.push.js";
import homeApi from '../../api/home/index';
export default {
  name: 'BusinessHeader',
  inject: ['reload'],
  data() {
    return {
      dialogVisible: false,
      activeUser: false,
      currentMenu: 0,
      menuList: [
      // { name: '首页',path: 'https://www.kuaizilife.com' },
      // 测试：http://web.kuailife888.com
      // 正式：https://www.kuaizilife.com
      // { name: '首页',path: 'https://www.kuaizilife.com' },
      {
        name: '首页',
        path: 'http://web.beta.kuailife888.com'
      }, {
        name: '外卖点餐',
        path: '/waimai/index'
      },
      // { name: '商城', path: '/shop/index' },
      // { name: '淘淘', path: '/taotao/index' },
      // { name: '充值兑换', path: '/user/index' },
      {
        name: '筷子头条',
        path: 'https://news.kuailife365.com'
      }, {
        name: '综合服务',
        path: 'https://www.kuaiziservice.com'
      }, {
        name: '入驻合作',
        path: '/league/index'
      }],
      // defaultImg: this.$config.DEFAULT_AVATAR,
      showPhoneCode: false,
      showInfo: false,
      showUser: false,
      passwordDialog: false,
      registerDialog: false
    };
  },
  computed: {
    token() {
      return this.$store.state.user.token;
    },
    profile() {
      return this.$store.state.user.profile;
    }
  },
  watch: {
    $route: {
      handler(val) {
        // if(!this.token) {
        //   return this.$router.push({ path: '/index' }).catch(err => {})
        // }
        this.menuList.forEach((item, index) => {
          if (item.path === val.path) {
            this.currentMenu = index;
            this.activeUser = false;
          }
        });
        if (val.name === 'User') {
          this.currentMenu = '';
          this.activeUser = true;
        }
        // 除入驻/个人中心，其他路由默认成外卖点餐
        if (val.name !== 'Index' && val.name !== 'User') {
          this.currentMenu = 1;
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    // 退出登录
    this.$bus.$on('handleLogout', data => {
      this.logout();
    }),
    // 打开登录/注册弹窗
    this.$bus.$on('openDialog', data => {
      if (data === 'login') {
        this.showUser = true;
      } else if (data === 'register') {
        this.registerDialog = true;
      }
    });
  },
  methods: {
    skip(path, index) {
      if (!path) {
        this.dialogVisible = true;
        return;
      }
      if (path.indexOf('http') >= 0) {
        if (index === 0) {
          // 原页面跳转应用
          window.location.href = path;
        } else {
          // 打开新页面跳转应用
          window.open(path);
        }
      } else {
        this.currentMenu = index;
        // 应用内跳转
        if (index === 4) {
          this.$router.push({
            path: path,
            query: {
              currentCollapse: 'balance',
              type: 'recharge'
            }
          });
        } else {
          this.$router.push({
            path: path
          });
        }
      }
    },
    // 打开官网地址
    openOfficial() {
      this.dialogVisible = false;
      // window.open('https://www.kuaizilife.com')
    },
    // 显示/隐藏手机二维码
    showPhone() {
      this.showPhoneCode = !this.showPhoneCode;
    },
    // 显示/隐藏消息图标
    handleInfo() {
      this.showInfo = !this.showInfo;
    },
    // 显示/隐藏用户
    handleUser() {
      this.showUser = !this.showUser;
    },
    // 回到首页
    // goHome() {
    //   this.$router.push({ path: '/index' })
    // },

    // 跳转个人中心页面
    toUser() {
      this.currentMenu = '';
      this.activeUser = true;
      this.$store.dispatch('user/userInfo');
      this.$router.push({
        path: '/user/index'
      });
    },
    // 退出登录
    async logout() {
      try {
        await this.$store.dispatch('user/logout');
        this.$message.success('退出登录成功');
        // this.goHome()
        this.reload();
      } catch (e) {}
    },
    // 确认登录
    async confirmLogin(obj) {
      var data = {};
      if (obj.passWord) {
        data = {
          mobile: obj.mobile,
          password: obj.passWord
        };
      } else if (obj.code) {
        data = {
          mobile: obj.mobile,
          code: obj.code
        };
      }
      var apiName = obj.passWord ? 'user/login' : 'user/loginSms';
      try {
        await this.$store.dispatch(apiName, data);
        this.$message.success('登录成功');
        this.handleUser();
        this.reload(); // 需要刷新页面
      } catch (e) {}
    },
    // 注册
    async register(obj) {
      const data = {
        username: obj.userName,
        mobile: obj.mobile,
        password: obj.passWord,
        code: obj.code
      };
      try {
        await this.$store.dispatch('user/register', data);
        this.$message.success('注册成功');
        this.handleRegister();
        this.showUser();
        this.reload();
        // 百度表单埋点
        window._agl && window._agl.push(['track', ['success', {
          t: 3
        }]]);
        // google表单埋点
        this.gtag_report_conversion('https://takeout.kuaizilife.com/#/index');
      } catch (e) {}
    },
    gtag_report_conversion(url) {
      var callback = function () {
        if (typeof url != 'undefined') {
          window.location = url;
        }
      };
      gtag('event', 'conversion', {
        'send_to': 'AW-10989685963/hMAcCNygtuADEMuZpfgo',
        'event_callback': callback
      });
      return false;
    },
    // 修改用户密码
    async confirmUpdate(obj) {
      const param = {
        mobile: obj.mobile,
        password: obj.passWord,
        code: obj.code
      };
      try {
        await homeApi.updatePassWord(param);
        this.$message.success('修改密码成功');
        this.handlePassWord();
        this.reload();
        // this.goHome()
      } catch (e) {}
    },
    // 打开/关闭忘记密码弹窗
    handlePassWord() {
      this.passwordDialog = !this.passwordDialog;
    },
    // 打开/关闭注册弹窗
    handleRegister() {
      this.registerDialog = !this.registerDialog;
    },
    // 返回上一级弹窗
    back() {
      this.showUser = true;
      this.registerDialog = false;
      this.passwordDialog = false;
    }
  }
};