/*
 * @Descripttion: 请求函数
 * @Date: 2022-03-30 11:21:02
 * @LastEditTime: 2022-09-01 11:51:20
 */

import axios from 'axios';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import $config from '@/config';
import store from '@/store';
import md5 from 'js-md5';
import { objKeySort } from '@/utils/index';
import qs from 'qs';
let reloadIndex = null;

// create an axios instance
const service = axios.create({
  // url = base url + request url
  timeout: 60000,
  // 超时处理
  validateStatus: status => {
    return status >= 200 && status < 500; // 默认的
  }
});

// 错误提示
const showError = msg => {
  window.$app.$message.error(msg || '未知错误');
};

// request 拦截
service.interceptors.request.use(config => {
  // 是否开启loading条
  if (config.loading !== false) {
    config.loading = true;
    NProgress.start();
    clearInterval(reloadIndex);
    reloadIndex = setInterval(() => {
      NProgress.inc(0.01);
    }, 100);
  }
  const baseUrl = config.domain || $config.API_DOMAIN + $config.BASE_URL;
  config.url = baseUrl + config.url;
  const sign_key = 'f0bc6fae953233ab'; // key是由后端指定的字符串
  const uid = store.state.user.profile.uid;
  // const uid = getToken() || ''
  const requesData = config.params || config.data || {};
  delete requesData.sign; // 删除sign
  const timestamp = parseInt(new Date().getTime() / 1000);

  // 添加必填公共参数
  requesData.uid = uid;
  requesData.timestamp = timestamp;
  const newObj = objKeySort(requesData, false);
  let sign = ''; // 加密参数
  Object.keys(newObj).forEach(function (key) {
    sign += key + '=' + newObj[key] + '&';
  });
  const signKey = sign + sign_key;
  // md5加密
  const md5Data = md5(signKey);
  requesData.sign = md5Data;
  // console.log(store.state.user, 'user****')

  if (store.state.user.token) {
    // 通过token字段传值，有时候会报跨域，主要可能是后台代码设置允许跨域的请求头字段中没有包括该字段
    config.headers['Authorization'] = store.state.user.token;
  } else {
    config.headers['Authorization'] = '';
  }

  // 非get请求 把params赋值给data，方便在api接口统一使用params参数
  if (config.method.toLowerCase() !== 'get') {
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
    const data = config.data = config.params ? config.params : config.data;
    config.data = qs.stringify(data); // 转为formdata数据格式
    config.params = '';
  }
  return config;
}, error => {
  // 关闭loading条
  clearInterval(reloadIndex);
  NProgress.done();
  return Promise.reject(error);
});

// response 拦截
service.interceptors.response.use(response => {
  // 关闭loading条
  clearInterval(reloadIndex);
  NProgress.done();

  // 文件流处理
  if (response.status === 200 && response.config.responseType === 'blob') {
    return response;
  }
  if (response.status == 200) {
    const code = response.data.code;
    const statusCode = [200, 201, 300, '200', '201', '300'];
    if (statusCode.includes(code)) {
      // 1 成功
      if (code == '201') {
        const temp = {
          msg: response.data.desc,
          code: response.data.code
        };
        return temp;
      }
      return response.data.data;
    } else {
      // console.log(124, response.data.code)
      switch (response.data.code) {
        case '400':
          showError(response.data.desc || '服务器错误');
          break;
        default:
          showError(response.data.desc || '服务器错误');
          break;
      }
      return Promise.reject({
        msg: response.data.desc,
        code: response.data.code
      });
    }
  } else {
    // 此处-处理状态码为200以外的逻辑
    switch (response.data.code) {
      case '10001':
        break;
      default:
        showError(response.data.desc || '服务器错误');
        break;
    }
    return Promise.reject({
      msg: response.data.desc,
      code: response.data.code
    });
  }
}, error => {
  clearInterval(reloadIndex);
  NProgress.done();
  console.log('请求出错：', error);
  return Promise.reject(error);
});
export default service;